import { useExceptionManagementTable } from 'components/services/management/exception/table/hooks/useExceptionManagementTable'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { FC } from 'react'
import { Loading, Table, UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	orsServiceExceptionRepository: OrsServiceExceptionRepository
}

const ExceptionManagementTable: FC<Props> = ({ orsServiceExceptionRepository }) => {
	const translate = useTranslator()

	const { orsServiceExceptions, heading, table } = useExceptionManagementTable({
		orsServiceExceptionRepository,
	})

	return (
		<>
			{heading.visible && (
				<UfinetSectionBox className="mb-3">
					<h3>{heading.content}</h3>
				</UfinetSectionBox>
			)}
			<UfinetSectionBox className={table.loading ? 'form-disabled position-relative' : ''}>
				<Table
					cols={table.columns}
					content={orsServiceExceptions.all}
					emptyMessage={table.loading ? translate('LOADING_DOTS') : undefined}
					headerButtons={table.headerButtons}
					afterTablePFSEvent={table.onPFSEvent}
					onValuesDisplayedChange={table.onValuesDisplayedChange}
				/>
				{table.loading && <Loading />}
			</UfinetSectionBox>
		</>
	)
}
export { ExceptionManagementTable }
