import dayjs from 'dayjs'
import { ContractedServiceTypeId } from 'modules/contractedService/domain/ContractedServiceType'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionState } from 'modules/contractedService/domain/ors/exception/state/OrsServiceExceptionState'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ContractedServiceStateExternal } from 'modules/contractedService/domain/state/ContractedServiceStateExternal'
import { IdAndValue } from 'ufinet-web-functions'

export type OrsServiceExceptionFindResponse = {
	id: string
	administrativeCode: string
	name: string
	type: ContractedServiceTypeId
	state: IdAndValue<number, ContractedServiceState | ContractedServiceStateExternal>
	client?: IdAndValue<string, string>
	term: number
	arcDifferential: number
	kam?: IdAndValue<string, string>
	exception: OrsServiceExceptionState
	exceptionDate?: string
	daysCurrentStatus: number
	arcCountry?: IdAndValue<string, string>
}

export const orsServiceExceptionFindResponseToDomainEntity: (
	orsServiceExceptionFindResponse: OrsServiceExceptionFindResponse
) => OrsServiceException = (orsServiceExceptionFindResponse) => ({
	service: {
		id: orsServiceExceptionFindResponse.id,
		administrativeCode: orsServiceExceptionFindResponse.administrativeCode,
		name: orsServiceExceptionFindResponse.name,
		type: orsServiceExceptionFindResponse.type,
		state: orsServiceExceptionFindResponse.state,
	},
	client: orsServiceExceptionFindResponse.client
		? { ...orsServiceExceptionFindResponse.client, name: orsServiceExceptionFindResponse.client.value }
		: undefined,
	term: orsServiceExceptionFindResponse.term,
	arcDifferential: orsServiceExceptionFindResponse.arcDifferential,
	kam: orsServiceExceptionFindResponse.kam
		? { ...orsServiceExceptionFindResponse.kam, name: orsServiceExceptionFindResponse.kam.value }
		: undefined,
	exception: {
		state: orsServiceExceptionFindResponse.exception,
		date: !orsServiceExceptionFindResponse.exceptionDate
			? undefined
			: dayjs(orsServiceExceptionFindResponse.exceptionDate),
	},
	daysCurrentStatus: orsServiceExceptionFindResponse.daysCurrentStatus,
	arcCountry: orsServiceExceptionFindResponse.arcCountry
		? {
				id: orsServiceExceptionFindResponse.arcCountry.id,
				name: orsServiceExceptionFindResponse.arcCountry.value,
		  }
		: undefined,
})
