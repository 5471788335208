import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'

import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { OrsStrategy } from './OrsStrategy'

export class OrsStrategyBilling implements OrsStrategy {
	public showForm(selectedPetition: OrsPetition, selectedEntry: OrsEntryType, _selectedOperations: OrsOperationType[]) {
		return selectedPetition !== undefined && selectedEntry !== undefined
	}

	public calculateIndividualServiceMinimumDate(
		_selectedOperations: OrsOperationType[],
		_serviceId: string,
		_services: ContractedService[]
	) {
		return undefined
	}

	public calculateUnifiedServicesMinimumDate(_selectedOperations: OrsOperationType[], _services: ContractedService[]) {
		return undefined
	}

	public isSelectedServiceInvalid(service: ContractedService, _selectedOperations: OrsOperationType[]) {
		return (
			![
				ContractedServiceState.ADJUDICADO,
				ContractedServiceState.EN_PLANIFICACION,
				ContractedServiceState.EN_CONSTRUCCION,
				ContractedServiceState.DETENIDO,
				ContractedServiceState.PENDIENTE_CLIENTE,
				ContractedServiceState.PENDIENTE_COMERCIAL,
				ContractedServiceState.EN_SERVICIO,
			].includes(service.state?.value as ContractedServiceState) || service.firstInvoiceDate !== null
		)
	}

	public enableOperations = false

	public enableReason = false

	public enableCompletionDate = true

	public isObservationRequired = true

	public defaultOperations = []

	public observationsLabel = 'CONTRACT.SERVICE.ORS.OBSERVATIONS'
}
