import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useExceptionManagementColsData } from 'components/services/management/exception/table/hooks/useExceptionManagementColsData'
import { useOrsServiceExceptionFindAllQuery } from 'modules/contractedService/application/ors/exception/find/useOrsServiceExceptionFindAllQuery'
import { OrsServiceExceptionStateSet } from 'modules/contractedService/application/ors/exception/update/dto/OrsServiceExceptionUpdateStateRequest'
import { useOrsServiceExceptionUpdateStateQuery } from 'modules/contractedService/application/ors/exception/update/useOrsServiceExceptionUpdateStateQuery'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { OrsServiceExceptionState } from 'modules/contractedService/domain/ors/exception/state/OrsServiceExceptionState'
import { DataTablePFSEvent } from 'primereact/datatable'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ColData, UfinetButton } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	defaultPageNumber,
	defaultPageSize,
	noContentPlaceholder,
	useTranslator,
} from 'ufinet-web-functions'

type HookInput = {
	orsServiceExceptionRepository: OrsServiceExceptionRepository
}

type HookOutput = {
	orsServiceExceptions: {
		all: OrsServiceException[]
	}
	heading: {
		visible: boolean
		content: string
	}
	table: {
		loading: boolean
		columns: ColData[]
		headerButtons?: JSX.Element
		onPFSEvent: (event: DataTablePFSEvent) => void
		onValuesDisplayedChange: (valuesDisplayed: OrsServiceException[]) => void
	}
}

function useExceptionManagementTable({ orsServiceExceptionRepository }: HookInput): HookOutput {
	const authData = useContext(AuthContext)
	const authRoles = authData.userData?.authorities || []
	const permissions = Authority.getGseExceptionPermissions(authRoles)

	const translate = useTranslator()
	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>()

	const orsExceptionStartIndex = useMemo(
		() => (tablePFSEvent?.page ?? defaultPageNumber) * (tablePFSEvent?.rows ?? defaultPageSize),
		[tablePFSEvent?.page, tablePFSEvent?.rows]
	)
	const orsExceptionEndIndex = useMemo(
		() => orsExceptionStartIndex + (tablePFSEvent?.rows ?? defaultPageSize),
		[orsExceptionStartIndex, tablePFSEvent?.rows]
	)

	const [orsServiceExceptions, setOrsServiceExceptions] = useState<OrsServiceException[]>([])
	const [displayedOrsServiceExceptions, setDisplayedOrsServiceExceptions] = useState<OrsServiceException[]>([])
	const visibleOrsServiceExceptions = useMemo(
		() => displayedOrsServiceExceptions.slice(orsExceptionStartIndex, orsExceptionEndIndex),
		[displayedOrsServiceExceptions, orsExceptionEndIndex, orsExceptionStartIndex]
	)
	const [nonManagedOrsServiceExceptions, setNonManagedOrsServiceExceptions] = useState<OrsServiceException[]>([])
	const [orsAllServiceExceptionsHeaderCheck, setOrsAllServiceExceptionsHeaderCheck] =
		useState<OrsServiceExceptionState>(OrsServiceExceptionState.EMPTY)

	const [orsServiceInitialStates, setOrsServiceInitialStates] = useState<
		{ id: string; state: OrsServiceExceptionState }[]
	>([])

	const orsServiceCurrentStates = useMemo(
		() => orsServiceExceptions.map((it) => ({ id: it.service.id, state: it.exception.state })),
		[orsServiceExceptions]
	)

	const orsServiceModifiedStates = useMemo(
		() =>
			orsServiceCurrentStates.filter((currentState) =>
				orsServiceInitialStates.find(
					(initialState) => initialState.id === currentState.id && initialState.state !== currentState.state
				)
			),
		[orsServiceCurrentStates, orsServiceInitialStates]
	)

	const {
		isLoading: loadingServiceExceptions,
		isFetching: fetchingServiceExceptions,
		refetch: fetchExceptions,
	} = useOrsServiceExceptionFindAllQuery(orsServiceExceptionRepository, {
		onSuccess: (serviceExceptions) => {
			setOrsServiceExceptions(serviceExceptions)
			setDisplayedOrsServiceExceptions(serviceExceptions)
			setOrsServiceInitialStates(serviceExceptions.map((it) => ({ id: it.service.id, state: it.exception.state })))
			setNonManagedOrsServiceExceptions(
				serviceExceptions.filter((it) => it.exception.state === OrsServiceExceptionState.EMPTY)
			)
		},
		onError: () => toast.error(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.FIND.ERROR')),
	})

	const { isLoading: updatingServiceStates, mutate: updateServiceStates } = useOrsServiceExceptionUpdateStateQuery(
		orsServiceExceptionRepository,
		{
			onSuccess: () => {
				toast.success(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.UPDATE.SUCCESS'))
				setOrsAllServiceExceptionsHeaderCheck(OrsServiceExceptionState.EMPTY)
				fetchExceptions()
			},
			onError: () => toast.error(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.UPDATE.ERROR')),
		}
	)

	const onExceptionStateChange = useCallback(
		({
			serviceIds,
			selectedState = OrsServiceExceptionState.EMPTY,
		}: {
			serviceIds: string[]
			selectedState: OrsServiceExceptionState
		}) => {
			if (
				orsAllServiceExceptionsHeaderCheck !== OrsServiceExceptionState.EMPTY &&
				orsAllServiceExceptionsHeaderCheck !== selectedState
			) {
				setOrsAllServiceExceptionsHeaderCheck(OrsServiceExceptionState.EMPTY)
			}

			setOrsServiceExceptions((oldValues) =>
				oldValues.map((serviceException) => ({
					...serviceException,
					exception: {
						...serviceException.exception,
						state: serviceIds.includes(serviceException.service.id) ? selectedState : serviceException.exception.state,
					},
				}))
			)
			setDisplayedOrsServiceExceptions((oldValues) =>
				oldValues.map((serviceException) => ({
					...serviceException,
					exception: {
						...serviceException.exception,
						state: serviceIds.includes(serviceException.service.id) ? selectedState : serviceException.exception.state,
					},
				}))
			)
		},
		[orsAllServiceExceptionsHeaderCheck]
	)

	const onExceptionAllStateChange = useCallback(
		(selectedState: OrsServiceExceptionState) => {
			const serviceIds = visibleOrsServiceExceptions.map((serviceException) => serviceException.service.id)

			onExceptionStateChange({ serviceIds, selectedState })
			setOrsAllServiceExceptionsHeaderCheck(selectedState)
		},
		[onExceptionStateChange, visibleOrsServiceExceptions]
	)

	useEffect(() => {
		const visibleExceptionStates = visibleOrsServiceExceptions.map(
			(serviceException) => serviceException.exception.state
		)

		setOrsAllServiceExceptionsHeaderCheck(
			visibleExceptionStates.length === 0
				? OrsServiceExceptionState.EMPTY
				: visibleExceptionStates.every((it) => it === OrsServiceExceptionState.WITH_EXCEPTION)
				? OrsServiceExceptionState.WITH_EXCEPTION
				: visibleExceptionStates.every((it) => it === OrsServiceExceptionState.WITHOUT_EXCEPTION)
				? OrsServiceExceptionState.WITHOUT_EXCEPTION
				: OrsServiceExceptionState.EMPTY
		)
	}, [displayedOrsServiceExceptions, orsExceptionEndIndex, orsExceptionStartIndex, visibleOrsServiceExceptions])

	const headerButtons = useMemo(
		() => (
			<>
				{(permissions.canWrite || permissions.canUpdate) && (
					<UfinetButton
						className="me-3"
						icon={faSave}
						onClick={() => {
							const updateSet: OrsServiceExceptionStateSet = {}
							orsServiceModifiedStates.forEach(({ id, state }) => (updateSet[id] = state))
							updateServiceStates({ updates: updateSet })
						}}
						content={translate('SAVE')}
						isDisabled={orsServiceModifiedStates.length === 0 || updatingServiceStates}
					/>
				)}
			</>
		),
		[orsServiceModifiedStates, permissions, translate, updateServiceStates, updatingServiceStates]
	)

	const { columns: tableColumns } = useExceptionManagementColsData({
		canEditState: permissions.canWrite || permissions.canUpdate,
		orsServiceExceptions,
		orsAllServiceExceptionsHeaderCheck,
		onExceptionStateChange,
		onExceptionAllStateChange,
	})

	return {
		orsServiceExceptions: {
			all: orsServiceExceptions,
		},
		heading: {
			visible: permissions.canWrite || permissions.canUpdate,
			content: `${translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.PENDING')}: ${
				loadingServiceExceptions || fetchingServiceExceptions
					? noContentPlaceholder
					: nonManagedOrsServiceExceptions.length
			}`,
		},
		table: {
			loading: loadingServiceExceptions || fetchingServiceExceptions || updatingServiceStates,
			columns: tableColumns,
			headerButtons,
			onPFSEvent: setTablePFSEvent,
			onValuesDisplayedChange: setDisplayedOrsServiceExceptions,
		},
	}
}

export { useExceptionManagementTable }
